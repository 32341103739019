import { typesWithElements } from '@visma/formula/lib/utils';
import { useCurrentUser } from '@visma/react-keycloak';
import produce from 'immer';
import { useMemo } from 'react';

// Original lodash `set` converts object to array, if setting number keys.
function set(obj, path, value) {
  path.forEach((key, index) => {
    if (index + 1 === path.length) {
      obj[key] = value;
    } else {
      obj = obj[key] ??= {};
    }
  });
}

// Returns array with key values of every formGroup element that is list.
function checkFormGroupList(config) {
  const elementList = [];
  if (config.type === 'form') {
    for (const element of config.elements) {
      if (element.type === 'formGroup' && element.list === true) {
        elementList.push(element.key.toString());
      }
    }
    return elementList;
  }
}

export default function usePrefillKeycloakFormData({ formData, config }) {
  const user = useCurrentUser();
  const prefilledFormData = useMemo(() => {
    if (user) {
      const keysToIgnore = checkFormGroupList(config);
      const formGroup = config.type === 'formGroup' && config.list;
      return produce({ formData }, (draft) => {
        (function prefillKeycloakData(config, path) {
          if (typesWithElements.includes(config.type)) {
            for (const element of config.elements ?? []) {
              prefillKeycloakData(element, [...path, element.key]);
            }
          } else {
            if (
              !formGroup &&
              config.fillIdentificationInfo &&
              config.identificationInfoType &&
              !keysToIgnore?.includes(
                path !== undefined && path.length === 3 && path[1]
              )
            ) {
              set(draft, path, user[config.identificationInfoType]);
            }
          }
        })(config, ['formData']);
      }).formData;
    }
    // Prefill formData only on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return user ? prefilledFormData : formData;
}
