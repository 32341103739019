import { Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { Alert } from '@material-ui/lab';
import { Form as FormulaForm, useAtomicFormSafe } from '@visma/formula';
import config from '@visma/public.config';
import { HasRole, IsAuthenticated } from '@visma/react-keycloak';
import Page from 'components/layouts/Page';
import { FormattedMessage } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { path as home } from '../home';
import FormStatus from './FormStatus';
import usePrefillKeycloakFormData from './usePrefillKeycloakFormData';
import parent from './_path';

export const path = `${parent}/:id`;

const UserNotAuthenticatedAlert = function () {
  return (
    <Alert severity="info">
      <FormattedMessage
        defaultMessage={
          'Tunnistaudu vahvasti "Kirjaudu sisään"-painikkeen kautta, jotta lomake avautuu.'
        }
      />
    </Alert>
  );
};

const FormNotFoundAlert = function () {
  return (
    <Alert severity="error" style={{ marginBottom: 10 }}>
      <FormattedMessage defaultMessage={'Lomaketta ei löydetty.'} />
    </Alert>
  );
};

export const component = function Form() {
  const { id } = useParams();
  const [error, form] = useAtomicFormSafe(id);

  const formElement = (
    <FormulaForm
      id={id}
      useLoadFormData={usePrefillKeycloakFormData}
      reviewProps={{
        showSuccessText: true,
        actions: (
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={home}
            replace
          >
            <FormattedMessage defaultMessage="Sulje" />
          </Button>
        ),
      }}
    />
  );

  return (
    <Page title={form ? form.title : ''}>
      {form && (
        <Container maxWidth="md">
          <HasRole resource={{ [config.keycloak.clientId]: 'configurer' }}>
            <FormStatus status={form.status} />
          </HasRole>
          {form.publicForm ? (
            formElement
          ) : (
            <IsAuthenticated fallback={<UserNotAuthenticatedAlert />}>
              {formElement}
            </IsAuthenticated>
          )}
        </Container>
      )}
      {error && (
        <Container maxWidth="md">
          <FormNotFoundAlert />
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={home}
            replace
          >
            <FormattedMessage defaultMessage="Palaa etusivulle" />
          </Button>
        </Container>
      )}
    </Page>
  );
};
